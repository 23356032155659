.collection-stat{
  background-image: linear-gradient(to bottom, #00386a, #002241, #000b15);
  border-radius: 8px;
  width: 100%;
  display: flex;
  height: 100%;

    .stat-text{
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
    }

  .number-text{
    display: flex;
    font-weight: bold;
    font-family: 'DINPro-Black';
    color: white;
    align-items: center;
    justify-content: center;
    font-size: xx-large;
  }

  .description-text{
    font-size: 14px;
    display: flex;
    font-family: 'DINPro-Black';
    font-weight: bold;
    align-items: center;
    justify-content: center;
    color: white;
  }
}