@import url('https://fonts.cdnfonts.com/css/dinpro-black');
html { font-family: 'DINPro-Black', sans-serif; }

.staking-section{
  background-color: black;
}

.staking-collections{
}

.collection-stat-section{
  padding-left: 60px;
  padding-right: 5px;

  @media screen and (max-width: 599px) {
    padding-right: 60px;
  }
}

.token-stat-section{
  padding-right: 60px;
  padding-left: 5px;

  @media screen and (max-width: 599px) {
    padding-left: 60px;
    padding-top: 15px;
  }
}

.stat-section{
  padding-top: 25px;
}

.button-section{
  padding-top: 20px;
}

.item-button{
  display: flex;
  justify-content: flex-end;
  align-content: center;
  padding-left: 10px;

  @media screen and (max-width: 599px) {
    padding-left: 60px;
  }
}

.empty-state{
  justify-content: center;
  align-content: center;
  margin-top: 50px;
}