@import url('https://fonts.cdnfonts.com/css/dinpro-black');

html {
  font-family: 'DINPro-Black', sans-serif;
}

.card {
  width: 14em;
  height: 100%;
}

.card-background {
  background-color: #001726;
  color: white;
  display: flex;
  align-items: center;
}

.card-footer-text {
  display: flex;
  align-items: center;
}

.item-number {
  font-size: x-large;
  font-family: 'DINPro-Black';
  display: flex;
  align-items: center;
}

.item-description {
  font-size: small;
  font-family: 'DINPro-Black';
  text-align: center;
}

.selected{
  border: 2px solid white;
  transform: scale(1.04);
}

.background-selected{
  background-color: #0097a1;
}