@import url('https://fonts.cdnfonts.com/css/dinpro-black');
html { font-family: 'DINPro-Black', sans-serif; }

.token-stat {
  background-image: linear-gradient(to bottom, #00eaff, #006d95, #001d38);
  border-radius: 8px;
  width: 100%;
  display: flex;
  padding: 10px;
}

.total-quack {
  font-family: 'DINPro-Black';
  font-size: xx-large;
  color: white;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 20px;

  @media screen and (max-width: 1560px) {
    font-size: x-large;
  }

  @media screen and (max-width: 1220px) {
    font-size: large;
  }

  @media screen and (max-width: 745px) {
    font-size: medium;
  }

  @media screen and (max-width: 695px) {
    font-size: small;
  }
}

.bouncing-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 6px;
}

.bouncing-loader > div {
  width: 7px;
  height: 7px;
  margin: 3px 3px;
  border-radius: 50%;
  background-color: #ffffff;
  opacity: 1;
  animation: bouncing-loader 0.6s infinite alternate;
}

@keyframes bouncing-loader {
  to {
    opacity: 0.1;
  }
}

.bouncing-loader > div:nth-child(2) {
  animation-delay: 0.2s;
}

.bouncing-loader > div:nth-child(3) {
  animation-delay: 0.4s;
}
