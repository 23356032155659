@import url('https://fonts.cdnfonts.com/css/dinpro-black');
html { font-family: 'DINPro-Black', sans-serif; }

.token-stat {
  background-image: linear-gradient(to bottom, #00eaff, #006d95, #001d38);
  border-radius: 8px;
  width: 100%;
  display: flex;
  padding: 10px;
}

.total-quack {
  font-family: 'DINPro-Black';
  font-size: xx-large;
  color: white;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 20px;

  @media screen and (max-width: 1560px) {
    font-size: x-large;
  }

  @media screen and (max-width: 1220px) {
    font-size: large;
  }

  @media screen and (max-width: 745px) {
    font-size: medium;
  }

  @media screen and (max-width: 695px) {
    font-size: small;
  }
}

.total-quack-to-be-claimed {
  font-size: xx-large;
  display: flex;
  font-family: 'DINPro-Black';
  font-weight: bold;
  align-items: center;
  color: white;
  justify-content: space-between;

  @media screen and (max-width: 1560px) {
    font-size: x-large;
  }

  @media screen and (max-width: 1220px) {
    font-size: large;
  }

  @media screen and (max-width: 695px) {
    justify-content: center;
  }
}
