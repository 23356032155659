@import url('https://fonts.cdnfonts.com/css/dinpro-black');
html { font-family: 'DINPro-Black', sans-serif; }

.container-card{
  border: 1px solid #EFEFF4;
  box-shadow: 0px 0px 5px #EFEFF4;
  border-radius: 8px;
  padding-bottom: 30.62px;
  padding-right: 16px;
  margin-top: 16px;

  background-image: linear-gradient(to bottom, #001D38, #006d95, #00eaff);
}

.subtype-title{
  font-family: 'DINPro-Black';
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 23px;

  display: flex;
  padding-left: 16px;
  align-items: center;
  justify-content: flex-start;

  color: white;

  @media only screen and (max-width: 600px) {
    font-size: 20px;
    justify-content: center;
  }
}

.header{
  height: 55px;
}

.section-title{
  font-family: 'DINPro-Black';
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  margin-bottom: 10px;
  color: #ffba00;

  @media only screen and (max-width: 600px) {
    text-align: center;
  }
}

.deck {
  justify-content: flex-start;
  padding-left: 24px;
}

.section{
  margin-right: 60px;
  margin-left: 60px;
  margin-top: 40px;
}